.focus-in-expand-fwd {
	-webkit-animation: focus-in-expand-fwd 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-expand-fwd 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.blur-out-contract-bck {
	-webkit-animation: blur-out-contract-bck 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: blur-out-contract-bck 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

 @-webkit-keyframes focus-in-expand-fwd {
    0% {
      letter-spacing: -0.5em;
      -webkit-transform: translateZ(-800px);
              transform: translateZ(-800px);
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes focus-in-expand-fwd {
    0% {
      letter-spacing: -0.5em;
      -webkit-transform: translateZ(-800px);
              transform: translateZ(-800px);
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }


 @-webkit-keyframes blur-out-contract-bck {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-filter: blur(0.01);
              filter: blur(0.01);
    }
    100% {
      letter-spacing: -0.5em;
      -webkit-transform: translateZ(-500px);
              transform: translateZ(-500px);
      -webkit-filter: blur(12px) opacity(0%);
              filter: blur(12px) opacity(0%);
    }
  }
  @keyframes blur-out-contract-bck {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-filter: blur(0.01);
              filter: blur(0.01);
    }
    100% {
      letter-spacing: -0.5em;
      -webkit-transform: translateZ(-500px);
              transform: translateZ(-500px);
      -webkit-filter: blur(12px) opacity(0%);
              filter: blur(12px) opacity(0%);
    }
  }
  